import "client/styles/globals.css";
import type { AppProps } from "next/app";
import { appWithTranslation } from "next-i18next";
import Head from "next/head";
import Script from "next/script";
import { useAds } from "client/hooks/utils/useAds";
import "cropperjs/dist/cropper.min.css";
import "components/modules/Editors/ImageResizer/Preview/Preview.css";
import { cdnUrl, url } from "client/config/url";
import { useRouter } from "next/router";
import translatedRoutedSlugs from "translatedRouteSlugs";
import { languageCodes } from "components/modules/Internationalization/Internationalization";
import { getUrlFromPathname } from "client/utils/getUrlFromPathname";
import {
    bottomModalPortalContainerId,
    modalAfterBottomModalContainerId,
    modalPortalContainerId,
} from "client/config/divIds";
import { ThemeProvider } from "client/contexts/ThemeProvider";
import dynamic from "next/dynamic";
import { Toaster } from "components/shadcn/toaster";

const FreshworksWidgetButton = dynamic<{}>(
    () => import("components/common/Freshworks/FreshworksWidgetButton").then((mod) => mod),
    { ssr: false },
);

const MyApp = ({ Component, pageProps }: AppProps) => {
    const { onScriptLoad } = useAds();
    const { pathname: _pathname, locale } = useRouter();
    const router = useRouter();
    const pathname = _pathname
        .replace(new RegExp("\\[", "g"), ":")
        .replace(new RegExp("\\]", "g"), "")
        .split("/")
        .map((chunk) => {
            if (chunk[0] === ":") {
                return chunk.replace(/([-_][a-z])/g, (group: string) =>
                    group.toUpperCase().replace("-", "").replace("_", ""),
                );
            }
            return chunk;
        })
        .join("/")
        .toLowerCase();

    return (
        <>
            <script
                dangerouslySetInnerHTML={{
                    __html: `
                        try {
                        if (localStorage.theme === 'dark' || ((!('theme' in localStorage) || localStorage.theme === 'system') && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
                            document.documentElement.classList.add('dark');
                            document.documentElement.style.colorScheme = "dark";
                            document.documentElement.style.backgroundColor = "#1c1e25";
                          } else {
                            document.documentElement.classList.remove('dark');
                          }
                        } catch (_) {}
                     `,
                }}
            />
            <Script type="text/javascript" async={true} src={`${cdnUrl}/prebid.js`} />
            <Script
                async={true}
                src="https://www.googletagmanager.com/gtag/js?id=UA-144096646-1"
                onLoad={onScriptLoad}
            />
            <Script
                dangerouslySetInnerHTML={{
                    __html: `
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){
                            dataLayer.push(arguments);
                        }
                        gtag('js', new Date());
                        gtag('config', 'UA-144096646-1');
                `,
                }}
            />
            <Head>
                <title>Image Resizer</title>
                <meta name="viewport" content="initial-scale=1.0, width=device-width, height=device-height" />
                <link
                    rel="canonical"
                    href={`${url}${locale === "en" ? "" : `/${locale}`}${
                        pathname === "/"
                            ? ""
                            : ("/" +
                                  getUrlFromPathname(
                                      (translatedRoutedSlugs as any)[pathname]?.[locale ?? "en"] ?? "",
                                      router,
                                  ) ?? pathname)
                    }`.toLowerCase()}
                />
                {languageCodes.map((code) => {
                    const slug =
                        pathname === "/"
                            ? ""
                            : (getUrlFromPathname((translatedRoutedSlugs as any)[pathname]?.[code], router) ?? "");
                    return (
                        <link
                            rel="alternate"
                            hrefLang={code}
                            href={`${url}${code === "en" ? "" : `/${code}`}${slug ? "/" : ""}${slug}`.toLowerCase()}
                        />
                    );
                })}
                <link
                    rel="alternate"
                    hrefLang="x-default"
                    href={`${url}${pathname === "/" ? "" : getUrlFromPathname(pathname, router)}`}
                />
                <meta
                    property="og:url"
                    content={`${url}${locale ? (locale === "en" ? "" : `/${locale}`) : ""}${
                        pathname === "/"
                            ? ""
                            : "/" +
                              getUrlFromPathname(
                                  (translatedRoutedSlugs as any)[pathname]?.[locale ?? "en"] ?? pathname,
                                  router,
                              )
                    }`}
                />
            </Head>

            <div id={modalPortalContainerId} />
            <div id={bottomModalPortalContainerId} />
            <div id={modalAfterBottomModalContainerId} />
            <div id="toasts_portal_container" />
            <div className="overflow-hidden text-black bg-white dark:bg-darkSurface-200">
                <ThemeProvider>
                    <Component {...pageProps} />
                    <Toaster />
                </ThemeProvider>
            </div>
            <FreshworksWidgetButton />
            <div data-aaad="true" data-aa-adunit="/275516369/IR_1x1"></div>
        </>
    );
};

export default appWithTranslation(MyApp);
